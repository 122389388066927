import Mock from "../mock";

const database = {
  information: {
    name: 'Gabrielle S. Balanon',
    aboutContent: "I am passionate about cultivating joy, compassion, and community everywhere I go. Previously a fullstack web developer at Twitter, currently looking to explore a new career. Oh, and please, call me Gabby. :-)",
    phone: '206-953-5022',
    pronouns: 'She/Her/Hers',
    language: 'English, Tagalog',
    email: '',
    living: 'San Francisco, CA',
    playing: 'countless ARAMs, unranked VALORANT matches, ridiculous Rocket League tournaments, and with my cats (of course)',
    socialLinks: {
      twitter: 'https://twitter.com/gbalan0n',
      linkedin: 'https://linkedin.com/in/gabrielle-balanon',
      dribbble: 'https://dribbble.com/just_gabby',
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image-lg.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/UpdatedResume2023.pdf'
  },
  services: [
  ],
  reviews: [
  ],
  skills: [
  ],
  portfolios: [
    {
      id: 1,
      title: "Twitter",
      subtitle: "Embedded Tweets and timelines",
      imageUrl: "/images/twete.png",
      url: "/files/embeds.html"
    },
    {
      id: 2,
      title: "UX/UI for Gaming",
      subtitle: "A case study on Minecraft Dungeons",
      imageUrl: "/images/dribble.png",
      url: 'https://dribbble.com/shots/19658350-UX-UI-for-Gaming'
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "July 2019 - Jan 2023",
        position: "Software Engineer II",
        company: "Twitter",
        details: "Rebuilt embedded Tweets on a modernized (React, Node.js) stack. \n Collaborated closely with product and design to make improvements to embedded Tweets with videos that resulted in ~20% lift in referred daily active users. Led, implemented, and conducted A/B experiments and worked with design and research to iterate on various health and public safety treatments for Tweets and timelines."
      },
      {
        id: 2,
        year: "Jun 2017 - Aug 2017",
        position: "(Returning) Undergraduate Teaching Assistant",
        company: "Girls Who Code",
        details: "Designed and taught curriculum to 20 high school students on how to program in Scratch, Python, HTML, CSS, and C over the course of 7 weeks at Microsoft in Redmond, WA for Girls Who Code's Summer Immersion Program. Lead lessons on how to debug code, how to wireframe their websites, and how to design with accessibility in mind (colors)."
      },
      {
        id: 3,
        year: "Dec 2016 - Dec 2017",
        position: "Educational Opportunity Program (STEM) Coordinator",
        company: "University of California, Berkeley",
        details: "Facilitated STEM and career based workshops at UC Berkeley. Designed, implemented, and marketed clear and accessible media infographics to showcase events and opportunities for networking and career planning using Canva."
      },
      {
        id: 4,
        year: "Jun 2016 - Aug 2016",
        position: "Undergraduate Teaching Assistant",
        company: "Girls Who Code",
        details: "Supported 20 high school students as they learned how to program in Scratch, Python, HTML, CSS, and C over the course of 7 weeks at GE Digital in San Ramon, CA for Girls Who Code's Summer Immersion Program. Reviewed code, encouraged and answered questions during class and office hours, and helped students learn how to use git and command-line."
      },
      {
        id: 5,
        year: "Aug 2014 - Jul 2015",
        position: "Program Coordinator",
        company: "All Girl Everything Ultimate Program (AGE UP)",
        details: "Worked with a small team to find community partners to guest facilitate workshops on life skills for 7th-12 grade youth from under-resourced, low-income communities in south Seattle. Facilitated workshops on public speaking and applying for scholarships, colleges, and jobs. Coached and mentored high school and middle school youth ultimate frisbee teams."

      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "June 2022 - Present",
        graduation: "Google UX Design Professional Certificate",
        university: "coursera.org",
        details: "Learning the design process, understanding the basics of UX research, and applying foundational UX concepts like user-centered design, accessibility, and equity-focused design to 3 end-to-end projects (mobile, responsive web, and cross-platform)."
      },
      {
        id: 2,
        year: "Aug 2022 - Present",
        graduation: "Unreal Engine 5 C++ Developer: Learn C++ & Make Video Games",
        university: "udemy.com",
        details: "Learning C++, game design principles, Unreal Gameplay Framework, and modern game development technologies and techniques."
      },
      {
        id: 2,
        year: "Jul 2022 - Aug 2022",
        graduation: "UX/UI for Gaming",
        university: "ELVTR.com",
        details: "An 8 week live online course on foundational UX and UI practices for video games taught by Ivy Sang (former Director of UX Design at EA) and hosted by ELVTR. Built a comprehensive case study of Minecraft Dungeons by mapping the player journey, creating low and high fidelty wireframes, and iterating on UI mockups after planning and conducting a usability test to gather feedback using Adobe Photoshop, Figma, and Zoom."

      },
      {
        id: 4,
        year: "Aug 2015 - May 2019",
        graduation: "B.A. in Cognitive Science",
        university: "University of California, Berkeley",
        details: "Participant of ANova, a student organization that seeks to empower students by providing computer science education and mentorship to under-resourced schools and marginalized communities in the Bay Area. Recipient of Bill & Melinda Gates Millennium Scholars scholarship."
      },
    ]
  },
  contactInfo: {
    phoneNumbers: ['+1 (206)-953-5022'],
    emailAddress: ['gabrielle.balanon@gmail.com'],
    address: "San Francisco, CA"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});